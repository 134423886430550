/**
 * Breadcrumbs navigation
 * Add class to list element if there's more than 2 list
 * items and the client supports CSS 'nth-last-child' selector.
 */

(function(w, d) {
	'use strict';

	var listEl = d.querySelector('.nav-breadcrumbs > ul');

	if (!listEl) {
		return;
	}

	try {
		listEl.querySelectorAll('li:nth-last-child(1)');

		if (listEl.querySelectorAll('li').length > 2) {
			listEl.classList.add('nav-breadcrumbs__list--truncate');
		}
	} catch (e) {}
})(this, document);
